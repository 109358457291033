<template>
  <div>
    <div v-if="!isMobile" class="m-4 p-4 bg-dark-jungle rounded">
      <h3 class="text-uppercase">{{ "Profile" | __t("member__headline__profile") }}</h3>
    </div>

    <div v-else @click="handleShowSidebar()" class="mt-3">
      <b-button variant="transparent" class="float-left">
        <img src="@/assets/icons/chevron-left.svg" width="20" height="20" />
      </b-button>

      <h5 class="mt-2 d-inline-block">{{ "Profile" | __t("member__headline__profile") }}</h5>
    </div>

    <b-row :class="{ 'm-4': !isMobile, 'm-2': isMobile }">
      <b-col lg="1"></b-col>

      <b-col cols="12" lg="10" class="profile bg-dark">
        <b-alert :show="isSuccessAlert" variant="success" @dismissed="isSuccessAlert = false" dismissible fade class="m-0 mt-3">
          {{ "Profile data updated successfully" | __t("member__profile-data-updated-successfully") }}
        </b-alert>

        <profile-edit-form
          v-if="isEditingProfile"
          @cancel="isEditingProfile = false"
          @success="
            isSuccessAlert = true;
            isEditingProfile = false;
          "
        />
        <email-update-form
          v-else-if="isEmailUpdate"
          @cancel="isEmailUpdate = false"
          @success="
            isSuccessAlert = true;
            isEmailUpdate = false;
          "
        />
        <password-update-form
          v-else-if="isPasswordUpdate"
          @cancel="isPasswordUpdate = false"
          @success="
            isSuccessAlert = true;
            isPasswordUpdate = false;
          "
        />

        <div :class="{ 'p-4': !isMobile }" class="m-auto rounded-md position-relative" v-else>
          <p>{{ "Greetings" | __t("message__greetings") }} {{ user?.displayName }},</p>

          <b-row no-gutters>
            <b-col cols="4">{{ "First Name" | __t("input__first-name") }}</b-col>
            <b-col>{{ user?.private?.firstName }}</b-col>
          </b-row>

          <b-row no-gutters>
            <b-col cols="4">{{ "Last Name" | __t("input__last-name") }}</b-col>
            <b-col>{{ user?.private?.lastName }}</b-col>
          </b-row>

          <b-row no-gutters>
            <b-col cols="4">{{ "Language" | __t("input__language") }}</b-col>
            <b-col>{{ LANGUAGE_ALIASES?.[user?.private?.language] || user?.private?.language }}</b-col>
          </b-row>

          <b-row no-gutters>
            <b-col cols="4">{{ "Email" | __t("input__email") }}</b-col>
            <b-col>{{ user?.email }}</b-col>
          </b-row>

          <b-button
            class="edit-profile mt-2 mt-md-0"
            variant="outline-primary"
            @click="
              isEditingProfile = true;
              isSuccessAlert = false;
            "
            pill
          >
            {{ "Edit profile" | __t("button__edit-profile") }}
          </b-button>

          <div class="divider border-top mt-4 mb-4" />

          <div class="d-flex justify-content-between align-items-stretch align-items-md-center flex-md-row flex-column">
            <div>
              <h5 class="m-0">
                {{ "Email Address" | __t("input__email") }}
              </h5>
              <small>{{ user?.email }}</small>
            </div>

            <b-button
              class="mt-2 mt-md-0"
              variant="outline-primary"
              style="width: inherit"
              @click="
                isEmailUpdate = true;
                isSuccessAlert = false;
              "
              pill
            >
              {{ "Change Email" | __t("button__change-email") }}
            </b-button>
          </div>

          <div class="divider border-top mt-4 mb-4" />

          <div class="d-flex justify-content-between align-items-stretch align-items-md-center flex-md-row flex-column">
            <h5 class="m-md-0">
              {{ "Password" | __t("members__headline__password") }}
            </h5>
            <b-button
              class="mt-2 mt-md-0"
              variant="outline-primary"
              style="width: inherit"
              @click="
                isPasswordUpdate = true;
                isSuccessAlert = false;
              "
              pill
            >
              {{ "Change Password" | __t("button__change-password") }}
            </b-button>
          </div>

          <div class="divider border-top mt-4 mb-4" />

          <h5 class="mb-4">{{ "Account Deletion" | __t("members__headline__account-deletion") }}</h5>

          <b-alert :show="hasError" variant="danger" dismissible fade class="m-0 mt-3">
            {{ "An error happened." | __t("message__an-error-happened") }}
          </b-alert>

          <p>
            <em>
              {{
                "All of your data and all the accounts you own will be immediatly destroyed" | __t("members__remove-alert")
              }}.<br />
              {{ "This action cannot be undone" | __t("members__remove-action") }}
            </em>
          </p>

          <b-button class="mt-4" variant="danger" @click="isShowingModal = true" pill>
            {{ "Remove user" | __t("button__remove-user") }}
          </b-button>
        </div>

        <div class="divider border-top mt-4 mb-4" />

        <p class="text-center">
          <small>UID: {{ user.uid }}</small>
        </p>
      </b-col>

      <b-col lg="1"></b-col>
    </b-row>

    <b-modal title="Confirm" v-model="isShowingModal" @hidden="isShowingModal = false" centered hide-footer>
      <p>
        {{
          "Confirm the deletion of your user data, the accounts you own and all the data related"
            | __t("members__confirm-suppression")
        }}.
      </p>

      <p>
        <em>{{ "This action cannot be undone" | __t("message__action-cannot-undone") }}.</em>
      </p>

      <div class="text-right">
        <b-button variant="empty" @click="isShowingModal = false" pill>
          {{ "Cancel" | __t("button__cancel") }}
        </b-button>

        <b-button variant="danger" @click="triggerRemoveUser" :disabled="isSending" pill>
          {{ "Delete" | __t("button__remove") }}
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { INIT, REMOVE } from "@/store/actions";

import { BRow, BCol, BButton, BModal, BAlert } from "bootstrap-vue";

import { LANGUAGE_ALIASES } from "@/constants/language";

import ProfileEditForm from "@/components/Forms/ProfileEdit";
import EmailUpdateForm from "@/components/Forms/EmailUpdate";
import PasswordUpdateForm from "@/components/Forms/PasswordUpdate";

export default {
  name: "Profile",
  components: { BRow, BCol, BButton, BModal, ProfileEditForm, EmailUpdateForm, BAlert, PasswordUpdateForm },
  computed: {
    ...mapState({ user: (state) => state.auth.user }),
    isMobile() {
      const { value } = this.$screen.alias;
      return value === "xs" || value === "sm" || value === "md";
    },
  },
  data: () => ({
    hasError: false,
    isSending: false,
    isShowingModal: false,
    isEditingProfile: false,
    isPasswordUpdate: false,
    isEmailUpdate: false,
    isSuccessAlert: false,

    LANGUAGE_ALIASES,
  }),
  methods: {
    ...mapActions("auth", { removeUser: REMOVE }),
    ...mapActions("locales", { initLanguage: INIT }), // Trigger when language is updated
    async triggerRemoveUser() {
      this.isSending = true;
      this.hasError = false;

      try {
        await this.removeUser();
      } catch (error) {
        console.log("err", error);
        this.hasError = true;
      }

      this.isSending = false;
    },

    handleShowSidebar() {
      this.$bus.emit("show-sidebar");
    },
  },
};
</script>

<style lang="scss" scoped>
@use "sass:map";
@import "@/assets/scss/_settings";

.profile {
  padding: 1.5em;
  border-radius: 0.5em;
  color: $white;
  background-color: $blue-deep;
  @media (max-width: map.get($grid-breakpoints, "md")) {
    padding: 1em;
  }
}

.edit-profile {
  position: absolute;
  top: 1.5em;
  right: 1.5em;
  width: auto;
  @media (max-width: map.get($grid-breakpoints, "md")) {
    position: relative;
    width: 100%;
    top: 0;
    right: 0;
  }
}

.wrapper {
  max-width: 600px;
}

.theme--default {
  .wrapper {
    background-color: $gray-light;
  }
}

.theme--dark {
  .wrapper {
    background-color: $blue-deeper;
  }
}
</style>
