<template>
  <b-form @submit="triggerEditProfile">
    <b-col cols="10">
      <errors v-if="errorMessage" :error="errorMessage" class="mb-4" data-cy="login-errors" />
      <div class="m-auto rounded-md p-4">
        <!-- <p>{{ "Hello" | __t }} {{ user.displayName }},</p> -->

        <fieldset class="form-group row" data-cy="login-fieldset">
          <b-col cols="4">{{ "First Name" | __t("input__first-name") }}</b-col>
          <b-col>
            <b-form-input
              name="firstname"
              type="text"
              :disabled="isSending"
              v-model="firstName"
              :placeholder="`Insert your first name` | __t('placeholder__first-name')"
              @keyup.enter="triggerEditProfile"
              data-cy="first-email"
              class="form-control"
            />

            <input-error
              v-if="!$v.firstName.required"
              :error-code="'required'"
              :has-submit-error.sync="hasSubmitError"
              data-cy="login-error"
            />
          </b-col>
        </fieldset>

        <fieldset class="form-group row" data-cy="lastname-fieldset">
          <b-col cols="4">{{ "Last Name" | __t("input__last-name") }}</b-col>
          <b-col>
            <b-form-input
              name="lastname"
              type="text"
              :disabled="isSending"
              v-model="lastName"
              :placeholder="`Insert your last name` | __t('placeholder__last-name')"
              @keyup.enter="triggerEditProfile"
              data-cy="last-name"
              class="form-control"
            />

            <input-error
              v-if="!$v.lastName.required"
              :error-code="'required'"
              :has-submit-error.sync="hasSubmitError"
              data-cy="lastname-error"
            />
          </b-col>
        </fieldset>

        <fieldset class="form-group row" data-cy="password-fieldset">
          <b-col cols="4">{{ "Language" | __t("input__language") }}</b-col>
          <b-col>
            <b-form-select
              v-model="language"
              :disabled="isSending"
              :options="langOptions"
              class="form-control mt-1"
              data-cy="select-input"
            ></b-form-select>
          </b-col>
        </fieldset>

        <b-row class="form-group" data-cy="password-fieldset">
          <b-col cols="4">{{ "Email" | __t("input__email") }}</b-col>
          <b-col>{{ user.email }}</b-col>
        </b-row>

        <b-row no-gutters>
          <b-button @click="triggerEditProfile" class="mr-3" variant="success" data-cy="login-submit" :disabled="isSending" pill>
            {{ "Save changes" | __t("button__save-changes") }}
          </b-button>
          <b-button @click="$emit('cancel')" variant="default" data-cy="login-submit" :disabled="isSending" pill>
            {{ "Cancel" | __t("button__cancel") }}
          </b-button>
        </b-row>
      </div>
    </b-col>
  </b-form>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { UPDATE, INIT } from "@/store/actions";

import { BForm, BFormInput, BButton, BRow, BCol, BFormSelect } from "bootstrap-vue";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

import { LANGUAGE_ALIASES, DEFAULT_LANGUAGE } from "@/constants/language";

import Errors from "@/components/Data/Errors";
import InputError from "@/components/Data/ErrorsInput";

export default {
  name: "ProfileEditForm",
  mixins: [validationMixin],
  components: { Errors, InputError, BForm, BFormInput, BButton, BRow, BCol, BFormSelect },
  computed: {
    ...mapState({ user: (state) => state.auth.user, currentLanguage: (state) => state.locales.language }),
    langOptions() {
      const options = [];
      for (let key in LANGUAGE_ALIASES) {
        options.push({ value: key, text: LANGUAGE_ALIASES[key] });
      }
      return options;
    },
  },
  data() {
    return {
      firstName: "",
      lastName: "",
      language: "",

      errorMessage: null,

      hasSubmitError: null,
      isSending: false,
    };
  },
  mounted() {
    this.firstName = this.user?.private?.firstName;
    this.lastName = this.user?.private?.lastName;
    this.language = this.user?.private?.language || DEFAULT_LANGUAGE;
  },
  methods: {
    ...mapActions("userPrivate", { update: UPDATE }),
    ...mapActions("locales", { setLanguage: INIT }),
    async triggerEditProfile(e) {
      if (e) e.preventDefault();

      this.errorMessage = null;

      this.$v.$touch();
      if (this.$v.$invalid) {
        this.hasSubmitError = true;
        return;
      }

      this.isSending = true;

      const { firstName, lastName, language } = this;

      // Language sets before user update:
      // Otherwise language variables can be overridden with default english versions
      if (language !== this.currentLanguage) {
        await this.setLanguage({ language });
      }

      const { isSuccess, error } = await this.update({ firstName, lastName, language });

      if (isSuccess) {
        this.$emit("success");
        return;
      }

      this.errorMessage = error;
      this.isSending = false;
    },
  },
  validations: {
    firstName: {
      required,
    },
    lastName: {
      required,
    },
  },
};
</script>
