<template>
  <b-form @submit="triggerForm" style="max-width: 700px; margin: auto">
    <b-row no-gutters>
      <b-col>
        <div class="m-auto rounded-md px-md-4 py-4">
          <b-alert v-if="errorMessage" show variant="danger" class="rounded-lg">
            <div>
              <p class="text-white mb-2">
                {{ errorMessage }}
              </p>
              <b-button v-if="showLogoutButton" variant="light" data-cy="login-submit" @click.prevent="triggerLogout" pill>
                {{ "Go to Login Page" | __t("forms__password-update__go-to-login") }}
              </b-button>
            </div>
          </b-alert>
          <!-- <p>{{ "Hello" | __t }} {{ user.displayName }},</p> -->

          <fieldset class="form-group mt-3 mb-2" data-cy="login-fieldset">
            <label for="email" class="mb-1">{{ "New Email:" | __t("forms__login__email") }}</label>
            <base-input
              name="email"
              type="email"
              id="email"
              :disabled="isSending"
              v-model="email"
              placeholder="mycoolmail@email.com"
              @keyup.enter="triggerLogin"
              data-cy="login-email"
            />

            <input-error
              v-if="!$v.email.required"
              :error-code="'required'"
              :has-submit-error.sync="hasSubmitError"
              data-cy="login-error"
            />
            <input-error
              v-if="!$v.email.email"
              :error-code="'email'"
              :has-submit-error.sync="hasSubmitError"
              data-cy="login-error"
            />
          </fieldset>

          <div class="control-buttons">
            <b-button @click="triggerForm" variant="success" data-cy="login-submit" :disabled="isSending" pill>
              {{ "Update Email" | __t("button__save-password") }}
            </b-button>
            <b-button @click="$emit('cancel')" variant="default" data-cy="login-submit" :disabled="isSending" pill>
              {{ "Cancel" | __t("button__cancel") }}
            </b-button>
          </div>
        </div>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { UPDATE_EMAIL, LOGOUT } from "@/store/actions";

import { BForm, BButton, BRow, BCol, BAlert } from "bootstrap-vue";
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";

import InputError from "@/components/Data/ErrorsInput";
import BaseInput from "@/components/Forms/Base/BaseInput";

export default {
  name: "ProfileEditForm",
  mixins: [validationMixin],
  components: { InputError, BAlert, BForm, BButton, BRow, BCol, BaseInput },
  computed: {
    ...mapState({ user: (state) => state.auth.user }),
  },
  data() {
    return {
      email: "",

      errorMessage: null,
      showLogoutButton: false,

      hasSubmitError: null,
      isSending: false,
    };
  },
  methods: {
    ...mapActions("userPrivate", { updateEmail: UPDATE_EMAIL }),
    ...mapActions("auth", { logOut: LOGOUT }),
    async triggerForm(e) {
      if (e) e.preventDefault();

      this.errorMessage = null;
      this.showLogoutButton = false;

      this.$v.$touch();
      if (this.$v.$invalid) {
        this.hasSubmitError = true;
        return;
      }

      this.isSending = true;

      const { email } = this;
      const { isSuccess, error, errorCode } = await this.updateEmail({ email });

      if (isSuccess) {
        this.isSending = false;
        this.$emit("success");
        return;
      }

      if (errorCode === "auth/requires-recent-login") {
        this.errorMessage = this.$options.filters.__t(
          "Your credentials are too old. Please login again to complete this action.",
          "forms__password-update__old-credentials"
        );
        this.showLogoutButton = true;
      } else {
        this.errorMessage = error;
      }

      this.isSending = false;
    },
    async triggerLogout() {
      await this.logOut();
    },
  },
  validations: {
    email: {
      required,
      email,
    },
  },
};
</script>
<style scoped lang="scss">
@use "sass:map";
@import "@/assets/scss/_settings";
.control-buttons {
  .btn {
    width: auto;
    @media (max-width: map.get($grid-breakpoints, "md")) {
      width: 100%;
    }
  }
}
.strength-meter {
  max-width: 100%;
  padding: 0 10px;
}
</style>
